
import {GChart} from 'vue-google-charts/legacy';
import {Component, Prop, Vue, Ref, Watch} from 'vue-property-decorator';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {DateContext, DateContextEvent, DateContextPeriod, DateContextTransaction} from '@/models/date-context';
import {GChartSeriesBuilder} from '@/util/GChartSeriesBuilder';
import {colors} from '@/config/common';
// import BaseChart from '@/components/Chart/BaseChart.vue';
// import {BarSeriesData,ChartBuilder,ChartType,Chart,BarSeries} from "@ticketengine/chart";
// import {ChartLegendSeries} from '@/models/chart';

@Component({
  components: {
    // BaseChart,
    GChart,
  }
})
export default class BarChart extends Vue {
  @Prop() private data!: AnalyticsApiQueryResponse;
  @Prop() private options!: any;
  @Prop() private dimensions!: string[];
  @Prop() private metrics!: string[];
  @Prop() private dateContext!: DateContext | DateContextTransaction | DateContextEvent | DateContextPeriod | undefined;

  get chartData(): any[] {
    return new GChartSeriesBuilder(this.data)
        // .setDateRangeDimension('te.dateRange')
        .setDateContext(this.dateContext)
        .setDimensions(this.dimensions)
        .setMetrics(this.metrics)
        .build();
  }

  get chartOptions(): any {
    const defaultOptions = {
      legend: {position: 'top'},
      height: 500,
      colors: colors,
      fontName: 'heebo',
      fontSize: 14,
      // bar: {groupWidth: '75%',},
      chartArea: {height: '90%'},
      // focusTarget: 'category',
      hAxis: {
        gridlines: {
          color: '#D0DCE2',
        },
        minorGridlines: {
          count: 0
        },
      },
      vAxis: {
        title: this.hasDimensions ? this.$t('metricsAndDimensions.name.'+this.dimensions[0]) : '',
        minorGridlines: {
          count: 0
        },
      },
    };
    return Object.assign(defaultOptions, this.options);
  }

  get hasMessage(): boolean {
    return !this.hasData || !this.hasDimensions || !this.hasMetrics;
  }

  get hasData(): boolean {
    return this.data && this.data.data.rows.length > 0;
  }

  get hasMetrics(): boolean {
    return this.metrics && this.metrics.length > 0;
  }

  get hasDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 0;
  }

  // @Prop() private series!: BarSeriesData[];
  // // @Ref('chart-container') readonly chartContainer!: HTMLOrSVGElement;
  // @Ref('chart-container') readonly chartContainer!: Element;
  // private chart?: Chart;
  //
  // get legendItems(): ChartLegendSeries[] {
  //     if(this.series && this.series[0] && this.series[0].data) {
  //         return this.series[0].data.map((d) => {
  //             return {
  //                 name: d.name,
  //                 context: d.context,
  //                 color: d.color,
  //             }
  //         });
  //     }
  //     return [];
  // }
  //
  // get hasSeries(): boolean {
  //     return this.series && this.series.length > 0;
  // }
  //
  // private renderChart(): void {
  //     // clear chart if one exists
  //     if(this.chart) {
  //         this.chart.clear();
  //     }
  //
  //     if(this.hasSeries) {
  //         // init chart
  //         const width = this.$el.clientWidth;
  //         let height = this.$el.parentElement ? this.$el.parentElement.clientHeight : this.$el.clientHeight;
  //         height = height > 200 ? height : 200;
  //
  //
  //         this.chart = new Chart(this.chartContainer, width, height, {top: 20, right: 20, bottom: 20, left: 40});
  //         const barSeries = new BarSeries(this.series);
  //         this.chart.addSeries(barSeries);
  //     }
  // }
  //
  //
  // @Watch('series')
  // seriesChanged(newSeries: BarSeriesData[]) {
  //     this.renderChart();
  // }
  //
  // mounted(): void {
  //     this.$nextTick(() => {
  //         if(this.series && this.series.length > 0) {
  //             this.renderChart();
  //         }
  //     });
  // }
}
