
import {Component, Prop, Vue, Ref, Watch} from 'vue-property-decorator';
import {GChart} from 'vue-google-charts/legacy';
import {colors} from '@/config/common';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {DateContext, DateContextEvent, DateContextPeriod, DateContextTransaction} from '@/models/date-context';
import {GChartSeriesBuilder} from '@/util/GChartSeriesBuilder';
// import BaseChart from '@/components/Chart/BaseChart.vue';
// import {SeriesData,Chart,PieSeries,PieSeriesData} from "@ticketengine/chart";
// import {ChartLegendSeries} from '@/models/chart';

@Component({
  components: {
    // BaseChart,
    GChart
  }
})
export default class PieChart extends Vue {
  @Prop() private data!: AnalyticsApiQueryResponse;
  @Prop() private options!: any;
  @Prop() private dimensions!: string[];
  @Prop() private metrics!: string[];
  @Prop() private dateContext!: DateContext | DateContextTransaction | DateContextEvent | DateContextPeriod | undefined;

  get chartData(): any[] {
    return new GChartSeriesBuilder(this.data)
        // .setDateRangeDimension('te.dateRange')
        .setDateContext(this.dateContext)
        .setDimensions(this.dimensions)
        .setMetrics(this.metrics)
        .build();
  }

  get chartOptions(): any {
    const defaultOptions = {
      legend: {position: 'top'},
      height: 400,
      colors: colors,
      fontName: 'heebo',
      fontSize: 14,
    };
    return Object.assign(defaultOptions, this.options);
  }

  get hasMessage(): boolean {
    return !this.hasData || !this.hasDimensions || !this.hasMetrics;
  }

  get hasData(): boolean {
    return this.data && this.data.data.rows.length > 0;
  }

  get hasMetrics(): boolean {
    return this.metrics && this.metrics.length > 0;
  }

  get hasDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 0;
  }

  // // @Prop({default:3}) private aspectRatioWidth!: number;
  // // @Prop({default:1}) private aspectRatioHeight!: number;
  // // @Prop() private series!: PieSeriesData[];
  // @Prop() private series!: PieSeriesData;
  // // @Ref('chart-container') readonly chartContainer!: HTMLOrSVGElement;
  // @Ref('chart-container') readonly chartContainer!: Element;
  // private chart?: Chart;
  //
  // get title(): string|null {
  //     if(this.series) {
  //         const context = this.series.context ? ' (' + this.series.context + ')' : '';
  //         return this.series.name + context;
  //     }
  //     return null;
  // }
  //
  // private renderChart(): void {
  //     // clear chart if one exists
  //     if(this.chart) {
  //         this.chart.clear();
  //     }
  //
  //     let size = this.$el.clientWidth;
  //     // if(size > 300) size = 300;
  //     this.chart = new Chart(this.chartContainer, size, size);
  //
  //     const pieSeries = new PieSeries(this.series);
  //     this.chart.addSeries(pieSeries);
  // }
  //
  // @Watch('series')
  // seriesChanged(newSeries: SeriesData[]) {
  //     this.renderChart();
  // }
  //
  // created(): void {
  //     this.$nextTick(() => {
  //         // if(this.series && this.series.length > 0) {
  //             this.renderChart();
  //         // }
  //     });
  // }
}
