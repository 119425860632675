
import {Component, Prop, Vue, Ref, Watch} from 'vue-property-decorator';
import {colors} from '@/config/common';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import DimensionTable from '@/components/DimensionTable.vue';
import {DimensionTableSeriesBuilder} from '@/util/DimensionTableSeriesBuilder';
import {
  DateContext,
  DateContextEvent,
  DateContextPeriod,
  DateContextTransaction, instanceOfDateContextPeriod, instanceOfDateContextTransaction,
} from '@/models/date-context';
import {
  DimensionTableDimension, DimensionTableLink,
  DimensionTableMetadataColumn,
  DimensionTableMetric,
  DimensionTableRow
} from '@/models/table';
import {FormatterFactory} from '@/util/FormatterFactory';
import {FormatDateString, Formatter} from '@/util/Formatter';
import {SegmentSummery} from '@/models/segment';
// import BaseChart from '@/components/Chart/BaseChart.vue';
// import {SeriesData,ChartBuilder,ChartType,Chart} from "@ticketengine/chart";
// import {ChartLegendSeries} from '@/models/chart';

@Component({
  components: {
    DimensionTable
  }
})
export default class TableChart extends Vue {
  @Prop() private data!: AnalyticsApiQueryResponse;
  @Prop() private options!: any;
  @Prop() private dimensions!: string[];
  @Prop() private metrics!: string[];
  @Prop() private dateContext!: DateContextTransaction | DateContextEvent | DateContextPeriod;
  @Prop() private selectedSegments!: SegmentSummery[];


  get chartData(): DimensionTableRow[] {
    if(this.data && this.dateContext) {
      const dimensions = this.chartDimensions.map(d => d.name);
      const metrics = this.chartMetrics.map(m => m.name);
      const builder = new DimensionTableSeriesBuilder(this.data, this.selectedSegments, dimensions, metrics, [], this.dateContext, this.chartMetadata);
      return builder.build();
    }
    return [];
  }


  get chartDimensions(): DimensionTableDimension[] {
    const dimensions: DimensionTableDimension[] = [];
    if(this.dimensions.length > 0) {
      dimensions.push({name: this.dimensions[0], label: ''});
    }
    if(this.dateContext && instanceOfDateContextPeriod(this.dateContext) && this.dateContext.compareTo.length > 0) {
      dimensions.push({name: 'te.period', label: this.$t('metricsAndDimensions.name.te.period')});
    }
    if(this.dateContext && instanceOfDateContextTransaction(this.dateContext) && this.dateContext.compareTo.length > 0) {
      dimensions.push({name: 'te.dateRange', label: this.$t('metricsAndDimensions.name.te.dateRange')});
    }
    if(this.selectedSegments && this.selectedSegments.length > 1) {
      dimensions.push({name: 'te.segment', label: this.$t('metricsAndDimensions.name.te.segment')});
    }
    return dimensions;
  }

  get chartMetrics(): DimensionTableMetric[] {
    return this.metrics.map((m) => {
      return {name: m, formatter: FormatterFactory.create(m),label: this.$t('metricsAndDimensions.name.' + m), description: this.$t('metricsAndDimensions.description.' + m)};
    });
  }

  get chartMetadata(): string[] {
    // return ['te.eventId', 'te.eventLocation', 'te.eventStartDateHourMinute']; // dimensions that need to be added as metadata
    return this.dimensions.filter((d, i) => i > 0);
  }

  get chartMetadataColumns(): DimensionTableMetadataColumn[] {
    // const dateFormatter = new FormatDateString('YYYYMMDDHHmm', 'MMM D YYYY, HH:mm');
    // return [
    //   {name: 'te.eventLocation', label: this.$t('metricsAndDimensions.name.te.eventLocation')},
    //   {name: 'te.eventStartDateHourMinute', label: this.$t('metricsAndDimensions.name.te.eventStartDate'), formatter: dateFormatter}
    // ]; // metadata that needs to be displayed in a column
    return this.chartMetadata.map((m) => {
      let formatter: Formatter|undefined = undefined;
      if(m.includes('DateHour')) formatter = new FormatDateString('YYYYMMDDHH', 'MMM D YYYY, HH');
      if(m.includes('DateHourMinute')) formatter = new FormatDateString('YYYYMMDDHHmm', 'MMM D YYYY, HH:mm');
      return {name: m, label: this.$t('metricsAndDimensions.name.'+m), formatter: formatter}
    });
  }

  get chartOptions(): object {
    const defaultOptions = {
      link: undefined,
      hideMetadataColumns: [] // list of dimension names of columns that will be hidden
    };
    return Object.assign({}, defaultOptions, this.options)
  }

  get chartTableLink(): DimensionTableLink|undefined {
    return undefined
    // return {
    //   routeName: 'event-detail',
    //   params: [{paramKey: 'id', metadata: 'te.eventId'}]
    // }
  }










  get hasMessage(): boolean {
    return !this.hasData || !this.hasDimensions || !this.hasMetrics;
  }

  get hasData(): boolean {
    return this.data && this.data.data.rows.length > 0;
  }

  get hasMetrics(): boolean {
    return this.metrics && this.metrics.length > 0;
  }

  get hasDimensions(): boolean {
    return this.dimensions && this.dimensions.length > 0;
  }

}
